import { createSlice } from '@reduxjs/toolkit';
import { clearStorageItem, setStorageItem } from 'services/storage.service';

const initialState = {
  auth: false,
  user: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      setStorageItem('token', action.payload.token);
      state.auth = !!action.payload.user;
      state.user = action.payload.user;
    },
    signOut: (state) => {
      state.auth = false;
      state.user = null;
      clearStorageItem('token');
      return state;
    }
  }
});

export const { signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
