const getStorageList = () => {
  const s = [];
  for (let i = 0; i < localStorage.length; i++) {
    s.push({
      key: localStorage.key(i),
      value: JSON.parse(localStorage.getItem(localStorage.key(i)))
    });
  }
  return s;
};

const getStorageItem = (key, dafault = null) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || dafault;
  } catch (e) {
    return dafault;
  }
};

const setStorageItem = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const clearStorageItem = (key) => {
  localStorage.removeItem(key);
};

export { getStorageList, getStorageItem, setStorageItem, clearStorageItem };
