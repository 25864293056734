import { styled } from '@mui/system';
import { Outlet } from 'react-router-dom';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function GuestLayout() {
  return (
    <ContentStyle>
      <Outlet />
    </ContentStyle>
  );
}
