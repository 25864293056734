import React from 'react';
import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { getStorageItem } from 'services/storage.service';

export default function ProtectedRoute({ children }) {
  const isLoggedIn = !!getStorageItem('token', false);

  return isLoggedIn ? children : <Navigate to="/login" replace />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired
};
