import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

import './App.css';
import ThemeConfig from './theme';
import Router from './routes';

function App() {
  return (
    <ThemeConfig>
      <Suspense fallback={<CircularProgress />}>
        <Router />
      </Suspense>
    </ThemeConfig>
  );
}

export default App;
