import SignOut from 'components/auth/SignOut';
import ProtectedRoute from 'components/ProtectedRoute';
import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import GuestLayout from 'layouts/GuestLayout';
import NotFound from 'pages/NotFound';

const Login = React.lazy(() => import('pages/Login'));
const CpPages = React.lazy(() => import('pages/cp'));

export default function Router() {
  return useRoutes([
    {
      path: 'cp/*',
      element: (
        <ProtectedRoute>
          <CpPages />
        </ProtectedRoute>
      )
    },

    {
      path: '/login',
      element: <GuestLayout />,
      children: [{ element: <Login /> }]
    },

    {
      path: '/signout',
      element: <SignOut />
    },

    { path: '/', element: <Navigate to="/cp" replace /> },

    { path: '/404', element: <NotFound /> },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
